.cluster-icon {
    background-color: white;
    height: 2.5em;
    width: 2.5em;
    color: black;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    font-size: 1.2rem;
    font-weight: 500;
    box-shadow: 0 0 0px 5px #1C64F2;
}

.leaflet-popup-content-wrapper {
    width: 360px;
    /*min-height: 150px;*/
    /*max-height: 350px;*/
    box-shadow: none !important;
    /*padding: 2.5rem 0 0 0 !important;*/
}

.leaflet-popup-content {
    width: 100% !important;
    height: 100%;
    margin: 0 !important;
    padding: .75rem;
}
.leaflet-popup-content p {
    margin: 0;
}
.leaflet-popup-close-button {
    display: none;
    width: 2.5rem !important;
    height: 2.5rem !important;
    padding: .5rem !important;
    font-size: 2.5rem !important;
}

.leaflet-popup-close-button {

}

.map-container {
    width: 100%;
    min-height: 50vh !important;
    /*max-height: 68% !important;*/
}

@media (max-width: 600px) {
    .leaflet-popup-content-wrapper {
        width: 75vw;
        min-height: 150px;
        box-shadow: none !important;
        padding: 1rem 0 0 0 !important;
    }

    .leaflet-popup-content {
        width: 100% !important;
        height: 100%;
        margin: 0 !important;
        padding: 1rem;
    }

    .leaflet-popup-close-button {
        width: 2.5rem !important;
        height: 2.5rem !important;
        padding: .5rem !important;
        font-size: 2.5rem !important;
    }

    .map-container {
        height: 60%;
        min-height: 60%;
        max-height: 60%;
    }
}